import React from "react";
import "./PrizeCard.css";

const PrizeCard = ({ title, image, description, gradientColor }) => {
  return (
    <div className={`prize-card ${gradientColor}`}>
      <img src={image} alt={title} className="prize-image" />
      <div className="prize-content">
        <h3 className="prize-title">{title}</h3>
        <p className="prize-description">{description}</p>
      </div>
    </div>
  );
};

export default PrizeCard;
