import React from "react";
import "./Prizes.css";
import PrizeCard from "../PrizeCard/PrizeCard";
import prizePodiumGraphic from "../../assets/img/themes/prizePodiumGraphic.png";
import firstPrizeImage from "../../assets/img/prizes/goldMedal.png";
import secondPrizeImage from "../../assets/img/prizes/silverMedal.png";
import thirdPrizeImage from "../../assets/img/prizes/bronzeMedal.png";

const Prizes = () => {
  return (
    <div className="container pt-20 pb-10" id="prizes">
      <h1
        className="text-center green-blue-text"
        style={{ fontFamily: "Poppins,sans-serif", fontWeight: 700 }}
      >
        Prizes
      </h1>

      <section className="pt-4 pb-10">
        <div className="">
          <div className="md:py-6 pb-8 px-2 lg:px-10 flex flex-col lg:flex-row justify-center items-center gap-4">
            <div className="lg:w-1/2 xl:w-[50%] p-6 lg:py-12 flex flex-col items-center justify-center md:items-start">
              <h2
                className="about-us-h2 pink-red-text mb-4"
                style={{ fontSize: "2.8rem" }}
              >
                Prizes under nameSpace Season of Open Source '24
              </h2>
              <p
                className="about-us-p text-justify"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "400",
                  fontSize: "1.3rem",
                  margin: "0",
                }}
              >
                See below the exciting prizes in store for the top performers
              </p>
            </div>
            <div className="lg:w-1/2 p-4 lg:p-16">
              <a href="" target="_blank" rel="noreferrer">
                <img
                  className="CommunityImage object-cover rounded-md transition-shadow"
                  src={prizePodiumGraphic}
                  alt="Prizes"
                />
              </a>
            </div>
          </div>
        </div>
      </section>

      <div className="cards-grid grid grid-cols-1 gap-4 place-items-center md:grid-cols-2 lg:grid-cols-3 lg:gap-8">
        <PrizeCard
          title="1st Prize"
          image={firstPrizeImage}
          description="Exciting rewards and goodies for the top performer"
          gradientColor="blue-cyan"
        />
        <PrizeCard
          title="2nd Prize"
          image={secondPrizeImage}
          description="Great rewards for the first runner-up"
          gradientColor="purple-pink"
        />
        <PrizeCard
          title="3rd Prize"
          image={thirdPrizeImage}
          description="Awesome rewards for the second runner-up"
          gradientColor="green-yellow"
        />
      </div>

      <div className="left-0 right-0 flex justify-center mt-4">
        <button
          className="button-76 absolute top-[35rem] sm:top-[18rem] md:top-[24rem] lg:top-[30rem] xl:top-[36rem] 2xl:top-[45rem]"
          style={{
            fontFamily: "Poppins,sans-serif",
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          View Rules
        </button>
      </div>
    </div>
  );
};

export default Prizes;
