const dummyProjects = [
  {
    id: "P001",
    title: "AI Chatbot",
    github: "https://github.com/example/ai-chatbot",
    deployed: "https://ai-chatbot.example.com",
    description: "A chatbot powered by AI to assist with customer queries.",
    admin: "Alice Johnson",
    techStack: ["Python", "TensorFlow", "Flask"],
    otherTags: ["AI", "Chatbot", "Customer Service"],
    mentors: ["Dr. Smith"],
    edition: "2024",
  },
  {
    id: "P002",
    title: "E-Commerce Platform",
    github: "https://github.com/example/e-commerce",
    deployed: "https://ecommerce.example.com",
    description: "An e-commerce platform for buying and selling products.",
    admin: "Bob Brown",
    techStack: ["React", "Node.js", "MongoDB"],
    otherTags: ["E-Commerce", "Web App", "Shopping"],
    mentors: ["Jane Doe"],
    edition: "2023",
  },
  {
    id: "P003",
    title: "Blockchain Voting System",
    github: "https://github.com/example/blockchain-voting",
    deployed: "https://blockchain-voting.example.com",
    description: "A secure voting system based on blockchain technology.",
    admin: "Charlie Davis",
    techStack: ["Ethereum", "Solidity", "JavaScript"],
    otherTags: ["Blockchain", "Voting", "Security"],
    mentors: ["Dr. Lee"],
    edition: "2022",
  },
  {
    id: "P004",
    title: "Fitness Tracker App",
    github: "https://github.com/example/fitness-tracker",
    deployed: "https://fitness-tracker.example.com",
    description: "An app to track fitness activities and progress.",
    admin: "Diana Evans",
    techStack: ["React Native", "Firebase", "Expo"],
    otherTags: ["Fitness", "Health", "Mobile App"],
    mentors: ["Dr. Martin"],
    edition: "2024",
  },
  {
    id: "P005",
    title: "Weather Dashboard",
    github: "https://github.com/example/weather-dashboard",
    deployed: "https://weather-dashboard.example.com",
    description: "A dashboard for viewing real-time weather data.",
    admin: "Eva Fisher",
    techStack: ["Angular", "OpenWeatherMap API", "Bootstrap"],
    otherTags: ["Weather", "Dashboard", "Web App"],
    mentors: ["Dr. Green"],
    edition: "2025",
  },
  {
    id: "P006",
    title: "Social Media Platform",
    github: "https://github.com/example/social-media",
    deployed: "https://social-media.example.com",
    description: "A platform for users to interact and share content.",
    admin: "Frank Garcia",
    techStack: ["Vue.js", "Django", "PostgreSQL"],
    otherTags: ["Social Media", "Networking", "Web App"],
    mentors: ["Dr. Black"],
    edition: "2022",
  },
  {
    id: "P007",
    title: "Online Learning Portal",
    github: "https://github.com/example/online-learning",
    deployed: "https://online-learning.example.com",
    description: "A portal for online courses and learning resources.",
    admin: "Grace Harris",
    techStack: ["PHP", "MySQL", "Laravel"],
    otherTags: ["Education", "E-Learning", "Web App"],
    mentors: ["Dr. White"],
    edition: "2026",
  },
  {
    id: "P008",
    title: "Virtual Reality Game",
    github: "https://github.com/example/vr-game",
    deployed: "https://vr-game.example.com",
    description: "A virtual reality game with immersive 3D environments.",
    admin: "Helen Jones",
    techStack: ["Unity", "C#", "VR"],
    otherTags: ["Gaming", "VR", "3D"],
    mentors: ["Dr. Collins"],
    edition: "2023",
  },
  {
    id: "P009",
    title: "Recipe Finder App",
    github: "https://github.com/example/recipe-finder",
    deployed: "https://recipe-finder.example.com",
    description: "An app to find recipes based on available ingredients.",
    admin: "Ian Taylor",
    techStack: ["React", "Node.js", "API Integration"],
    otherTags: ["Food", "Recipes", "Web App"],
    mentors: ["Dr. Patel"],
    edition: "2024",
  },
  {
    id: "P010",
    title: "Project Management Tool",
    github: "https://github.com/example/project-management",
    deployed: "https://project-management.example.com",
    description: "A tool for managing projects and tasks efficiently.",
    admin: "Jack Wilson",
    techStack: ["Angular", "Express", "MongoDB"],
    otherTags: ["Productivity", "Management", "Web App"],
    mentors: ["Dr. Kim"],
    edition: "2025",
  },
  {
    id: "P011",
    title: "Travel Booking System",
    github: "https://github.com/example/travel-booking",
    deployed: "https://travel-booking.example.com",
    description: "A system for booking flights and hotels.",
    admin: "Laura Martinez",
    techStack: ["Vue.js", "Ruby on Rails", "PostgreSQL"],
    otherTags: ["Travel", "Booking", "Web App"],
    mentors: ["Dr. Adams"],
    edition: "2022",
  },
  {
    id: "P012",
    title: "Real Estate Platform",
    github: "https://github.com/example/real-estate",
    deployed: "https://real-estate.example.com",
    description: "A platform for buying and selling real estate properties.",
    admin: "Mike Lewis",
    techStack: ["React", "Django", "MySQL"],
    otherTags: ["Real Estate", "Properties", "Web App"],
    mentors: ["Dr. Anderson"],
    edition: "2026",
  },
  {
    id: "P013",
    title: "Task Automation Tool",
    github: "https://github.com/example/task-automation",
    deployed: "https://task-automation.example.com",
    description: "A tool for automating repetitive tasks.",
    admin: "Nancy Clark",
    techStack: ["Python", "Celery", "Redis"],
    otherTags: ["Automation", "Productivity", "Python"],
    mentors: ["Dr. Thompson"],
    edition: "2023",
  },
  {
    id: "P014",
    title: "Personal Finance Manager",
    github: "https://github.com/example/finance-manager",
    deployed: "https://finance-manager.example.com",
    description: "An app for managing personal finances and budgeting.",
    admin: "Oliver Young",
    techStack: ["Flutter", "Firebase", "Dart"],
    otherTags: ["Finance", "Budgeting", "Mobile App"],
    mentors: ["Dr. Walker"],
    edition: "2024",
  },
  {
    id: "P015",
    title: "IoT Home Automation",
    github: "https://github.com/example/iot-home",
    deployed: "https://iot-home.example.com",
    description: "A system for automating home devices using IoT.",
    admin: "Paul King",
    techStack: ["Arduino", "MQTT", "Node.js"],
    otherTags: ["IoT", "Home Automation", "Embedded Systems"],
    mentors: ["Dr. Lewis"],
    edition: "2022",
  },
  {
    id: "P016",
    title: "Music Streaming Service",
    github: "https://github.com/example/music-streaming",
    deployed: "https://music-streaming.example.com",
    description: "A service for streaming music online.",
    admin: "Quinn Scott",
    techStack: ["React", "Node.js", "MongoDB"],
    otherTags: ["Music", "Streaming", "Web App"],
    mentors: ["Dr. Johnson"],
    edition: "2025",
  },
  {
    id: "P017",
    title: "Augmented Reality App",
    github: "https://github.com/example/ar-app",
    deployed: "https://ar-app.example.com",
    description: "An app using augmented reality to enhance user experiences.",
    admin: "Rita Bell",
    techStack: ["ARKit", "Swift", "CoreML"],
    otherTags: ["AR", "Mobile App", "Swift"],
    mentors: ["Dr. Turner"],
    edition: "2023",
  },
  {
    id: "P018",
    title: "Healthcare Management System",
    github: "https://github.com/example/healthcare-management",
    deployed: "https://healthcare-management.example.com",
    description: "A system for managing patient records and appointments.",
    admin: "Steve Wright",
    techStack: ["Java", "Spring Boot", "MySQL"],
    otherTags: ["Healthcare", "Management", "Web App"],
    mentors: ["Dr. Davis"],
    edition: "2024",
  },
  {
    id: "P019",
    title: "Chat Application",
    github: "https://github.com/example/chat-app",
    deployed: "https://chat-app.example.com",
    description: "A real-time chat application with messaging features.",
    admin: "Tina Harris",
    techStack: ["Socket.io", "Express", "MongoDB"],
    otherTags: ["Chat", "Real-time", "Web App"],
    mentors: ["Dr. Miller"],
    edition: "2026",
  },
  {
    id: "P020",
    title: "Portfolio Website",
    github: "https://github.com/example/portfolio",
    deployed: "https://portfolio.example.com",
    description: "A personal portfolio website to showcase projects and skills.",
    admin: "Uma Lewis",
    techStack: ["HTML", "CSS", "JavaScript"],
    otherTags: ["Portfolio", "Personal", "Web App"],
    mentors: ["Dr. Lee"],
    edition: "2022",
  },
  {
    id: "P021",
    title: "Online Quiz Platform",
    github: "https://github.com/example/quiz-platform",
    deployed: "https://quiz-platform.example.com",
    description: "A platform for creating and taking online quizzes.",
    admin: "Victor Allen",
    techStack: ["React", "Node.js", "SQLite"],
    otherTags: ["Education", "Quizzes", "Web App"],
    mentors: ["Dr. Green"],
    edition: "2025",
  },
  {
    id: "P022",
    title: "Photo Editing App",
    github: "https://github.com/example/photo-editor",
    deployed: "https://photo-editor.example.com",
    description: "An app for editing and enhancing photos.",
    admin: "Wendy Baker",
    techStack: ["JavaScript", "Canvas API", "Node.js"],
    otherTags: ["Photo Editing", "Mobile App", "JavaScript"],
    mentors: ["Dr. Smith"],
    edition: "2024",
  },
  {
    id: "P023",
    title: "Job Portal",
    github: "https://github.com/example/job-portal",
    deployed: "https://job-portal.example.com",
    description: "A job portal for employers and job seekers to connect.",
    admin: "Xander Carter",
    techStack: ["Angular", "Spring Boot", "MongoDB"],
    otherTags: ["Jobs", "Portal", "Web App"],
    mentors: ["Dr. Brown"],
    edition: "2023",
  },
  {
    id: "P024",
    title: "Event Management System",
    github: "https://github.com/example/event-management",
    deployed: "https://event-management.example.com",
    description: "A system for organizing and managing events.",
    admin: "Yvonne Cooper",
    techStack: ["React", "Express", "PostgreSQL"],
    otherTags: ["Events", "Management", "Web App"],
    mentors: ["Dr. Anderson"],
    edition: "2026",
  },
  {
    id: "P025",
    title: "Fitness Challenge App",
    github: "https://github.com/example/fitness-challenge",
    deployed: "https://fitness-challenge.example.com",
    description: "An app to join and track fitness challenges.",
    admin: "Zachary Bell",
    techStack: ["React Native", "Node.js", "MongoDB"],
    otherTags: ["Fitness", "Challenges", "Mobile App"],
    mentors: ["Dr. Lee"],
    edition: "2022",
  },
  {
    id: "P026",
    title: "Stock Market Analysis Tool",
    github: "https://github.com/example/stock-analysis",
    deployed: "https://stock-analysis.example.com",
    description: "A tool for analyzing stock market trends and data.",
    admin: "Alice Walker",
    techStack: ["Python", "Pandas", "Matplotlib"],
    otherTags: ["Finance", "Analysis", "Python"],
    mentors: ["Dr. Lewis"],
    edition: "2025",
  },
  {
    id: "P027",
    title: "Recipe Sharing Platform",
    github: "https://github.com/example/recipe-sharing",
    deployed: "https://recipe-sharing.example.com",
    description: "A platform for users to share and discover recipes.",
    admin: "Bob Harris",
    techStack: ["Vue.js", "Laravel", "MySQL"],
    otherTags: ["Recipes", "Sharing", "Web App"],
    mentors: ["Dr. Green"],
    edition: "2024",
  },
  {
    id: "P028",
    title: "Language Learning App",
    github: "https://github.com/example/language-learning",
    deployed: "https://language-learning.example.com",
    description: "An app for learning new languages through interactive lessons.",
    admin: "Charlie Davis",
    techStack: ["Flutter", "Firebase", "Dart"],
    otherTags: ["Education", "Language", "Mobile App"],
    mentors: ["Dr. Thompson"],
    edition: "2023",
  },
  {
    id: "P029",
    title: "Smart Home Controller",
    github: "https://github.com/example/smart-home",
    deployed: "https://smart-home.example.com",
    description: "A system to control smart home devices from a single app.",
    admin: "Diana Green",
    techStack: ["Arduino", "MQTT", "Node.js"],
    otherTags: ["IoT", "Home Automation", "Embedded Systems"],
    mentors: ["Dr. Black"],
    edition: "2026",
  },
  {
    id: "P030",
    title: "Online Auction Platform",
    github: "https://github.com/example/auction-platform",
    deployed: "https://auction-platform.example.com",
    description: "A platform for online auctions of various items.",
    admin: "Eva Carter",
    techStack: ["React", "Express", "MongoDB"],
    otherTags: ["Auction", "Marketplace", "Web App"],
    mentors: ["Dr. Adams"],
    edition: "2022",
  },
  {
    id: "P031",
    title: "Fitness Tracker for Wearables",
    github: "https://github.com/example/fitness-wearables",
    deployed: "https://fitness-wearables.example.com",
    description: "A fitness tracking app designed for wearable devices.",
    admin: "Frank Scott",
    techStack: ["React Native", "Node.js", "Wearables SDK"],
    otherTags: ["Wearables", "Fitness", "Mobile App"],
    mentors: ["Dr. Collins"],
    edition: "2024",
  },
  {
    id: "P032",
    title: "Cryptocurrency Tracker",
    github: "https://github.com/example/crypto-tracker",
    deployed: "https://crypto-tracker.example.com",
    description: "A tool to track cryptocurrency prices and trends.",
    admin: "Grace Lee",
    techStack: ["Vue.js", "Node.js", "API Integration"],
    otherTags: ["Cryptocurrency", "Finance", "Web App"],
    mentors: ["Dr. Green"],
    edition: "2025",
  },
  {
    id: "P033",
    title: "E-Learning Platform",
    github: "https://github.com/example/e-learning",
    deployed: "https://e-learning.example.com",
    description: "An e-learning platform for online courses and resources.",
    admin: "Hank Johnson",
    techStack: ["Angular", "Spring Boot", "MySQL"],
    otherTags: ["Education", "E-Learning", "Web App"],
    mentors: ["Dr. Smith"],
    edition: "2023",
  },
  {
    id: "P034",
    title: "Photo Gallery App",
    github: "https://github.com/example/photo-gallery",
    deployed: "https://photo-gallery.example.com",
    description: "An app for creating and managing photo galleries.",
    admin: "Ivy Brown",
    techStack: ["React", "Node.js", "AWS S3"],
    otherTags: ["Photography", "Gallery", "Web App"],
    mentors: ["Dr. Adams"],
    edition: "2026",
  },
  {
    id: "P035",
    title: "Expense Tracker App",
    github: "https://github.com/example/expense-tracker",
    deployed: "https://expense-tracker.example.com",
    description: "An app for tracking personal expenses and budgeting.",
    admin: "John Smith",
    techStack: ["Flutter", "Firebase", "Dart"],
    otherTags: ["Finance", "Expense", "Mobile App"],
    mentors: ["Dr. Patel"],
    edition: "2024",
  },
  {
    id: "P036",
    title: "Real-Time Weather App",
    github: "https://github.com/example/weather-app",
    deployed: "https://weather-app.example.com",
    description: "A real-time weather app with accurate forecasts.",
    admin: "Kara Davis",
    techStack: ["React", "Node.js", "Weather API"],
    otherTags: ["Weather", "Forecast", "Web App"],
    mentors: ["Dr. Walker"],
    edition: "2025",
  },
  {
    id: "P037",
    title: "Virtual Meeting Platform",
    github: "https://github.com/example/meeting-platform",
    deployed: "https://meeting-platform.example.com",
    description: "A platform for hosting virtual meetings and video calls.",
    admin: "Liam Scott",
    techStack: ["WebRTC", "Node.js", "Express"],
    otherTags: ["Video Conferencing", "Meetings", "Web App"],
    mentors: ["Dr. Brown"],
    edition: "2023",
  },
  {
    id: "P038",
    title: "AI-Powered Personal Assistant",
    github: "https://github.com/example/personal-assistant",
    deployed: "https://personal-assistant.example.com",
    description: "An AI-powered personal assistant for scheduling and reminders.",
    admin: "Mia Allen",
    techStack: ["Python", "NLTK", "Flask"],
    otherTags: ["AI", "Assistant", "Productivity"],
    mentors: ["Dr. Green"],
    edition: "2024",
  },
  {
    id: "P039",
    title: "Task Management App",
    github: "https://github.com/example/task-management",
    deployed: "https://task-management.example.com",
    description: "An app for managing tasks and to-do lists.",
    admin: "Noah Wilson",
    techStack: ["React", "Redux", "Node.js"],
    otherTags: ["Productivity", "Tasks", "Web App"],
    mentors: ["Dr. Smith"],
    edition: "2026",
  },
  {
    id: "P040",
    title: "Online Store for Artisans",
    github: "https://github.com/example/artisan-store",
    deployed: "https://artisan-store.example.com",
    description: "An online store for handmade crafts and artisan products.",
    admin: "Olivia Johnson",
    techStack: ["Vue.js", "Express", "MongoDB"],
    otherTags: ["E-Commerce", "Crafts", "Web App"],
    mentors: ["Dr. Anderson"],
    edition: "2022",
  },
  {
    id: "P041",
    title: "Gaming Community Forum",
    github: "https://github.com/example/gaming-forum",
    deployed: "https://gaming-forum.example.com",
    description: "A forum for gamers to discuss and share tips.",
    admin: "Peter Martinez",
    techStack: ["React", "Node.js", "Socket.io"],
    otherTags: ["Gaming", "Community", "Web App"],
    mentors: ["Dr. White"],
    edition: "2025",
  },
  {
    id: "P042",
    title: "Library Management System",
    github: "https://github.com/example/library-management",
    deployed: "https://library-management.example.com",
    description: "A system for managing library books and member records.",
    admin: "Quincy Thompson",
    techStack: ["Java", "Spring Boot", "MySQL"],
    otherTags: ["Library", "Management", "Web App"],
    mentors: ["Dr. Collins"],
    edition: "2024",
  },
  {
    id: "P043",
    title: "Digital Marketplace",
    github: "https://github.com/example/digital-marketplace",
    deployed: "https://digital-marketplace.example.com",
    description: "A digital marketplace for buying and selling digital goods.",
    admin: "Rachel Green",
    techStack: ["Angular", "Node.js", "PostgreSQL"],
    otherTags: ["Marketplace", "E-Commerce", "Web App"],
    mentors: ["Dr. Turner"],
    edition: "2026",
  },
  {
    id: "P044",
    title: "Fitness App with Wearable Integration",
    github: "https://github.com/example/fitness-wearable",
    deployed: "https://fitness-wearable.example.com",
    description: "A fitness app integrated with wearable devices for tracking.",
    admin: "Sam Brown",
    techStack: ["React Native", "Firebase", "Wearables SDK"],
    otherTags: ["Fitness", "Wearables", "Mobile App"],
    mentors: ["Dr. Patel"],
    edition: "2023",
  },
  {
    id: "P045",
    title: "Recipe Recommendation System",
    github: "https://github.com/example/recipe-recommendation",
    deployed: "https://recipe-recommendation.example.com",
    description: "A system for recommending recipes based on user preferences.",
    admin: "Tina Clark",
    techStack: ["Python", "Flask", "Machine Learning"],
    otherTags: ["Recipes", "Recommendation", "Python"],
    mentors: ["Dr. Johnson"],
    edition: "2024",
  },
  {
    id: "P046",
    title: "Event Ticketing System",
    github: "https://github.com/example/ticketing-system",
    deployed: "https://ticketing-system.example.com",
    description: "A system for selling and managing event tickets.",
    admin: "Ursula Smith",
    techStack: ["React", "Node.js", "MongoDB"],
    otherTags: ["Events", "Ticketing", "Web App"],
    mentors: ["Dr. Walker"],
    edition: "2022",
  },
  {
    id: "P047",
    title: "Travel Itinerary Planner",
    github: "https://github.com/example/itinerary-planner",
    deployed: "https://itinerary-planner.example.com",
    description: "An app for planning and managing travel itineraries.",
    admin: "Victor Brown",
    techStack: ["Vue.js", "Express", "MySQL"],
    otherTags: ["Travel", "Itinerary", "Web App"],
    mentors: ["Dr. Adams"],
    edition: "2025",
  },
  {
    id: "P048",
    title: "Cryptocurrency Portfolio Tracker",
    github: "https://github.com/example/crypto-portfolio",
    deployed: "https://crypto-portfolio.example.com",
    description: "A tool for tracking and managing cryptocurrency investments.",
    admin: "Wendy Johnson",
    techStack: ["Angular", "Spring Boot", "PostgreSQL"],
    otherTags: ["Cryptocurrency", "Finance", "Web App"],
    mentors: ["Dr. Brown"],
    edition: "2026",
  },
  {
    id: "P049",
    title: "Machine Learning Model Trainer",
    github: "https://github.com/example/ml-model-trainer",
    deployed: "https://ml-model-trainer.example.com",
    description: "A tool for training and evaluating machine learning models.",
    admin: "Xander Green",
    techStack: ["Python", "TensorFlow", "Jupyter Notebooks"],
    otherTags: ["Machine Learning", "Python", "Data Science"],
    mentors: ["Dr. Lee"],
    edition: "2024",
  },
  {
    id: "P050",
    title: "Personal Portfolio Website",
    github: "https://github.com/example/personal-portfolio",
    deployed: "https://personal-portfolio.example.com",
    description: "Lorem ipsum odor amet, consectetuer adipiscing elit. Interdum porta elementum vel integer litora aenean lectus luctus risus scelerisque tempus vel mattis curabitur finibus felis potenti vitae natoque torquent non luctus pharetra at nisi maecenas pharetra amet varius risus et habitasse maecenas convallis donec vehicula neque nibh tortor sapien ornare aliquet eget magna ornare luctus eleifend diam pulvinar phasellus aliquam neque felis nunc in porttitor diam commodo faucibus mus semper cursus integer amet dis dictumst imperdiet gravida varius nec habitasse fermentum scelerisque platea velit natoque vehicula molestie senectus egestas elit sit odio cubilia sollicitudin varius habitasse torquent ex integer primis commodo purus quis utate fames praesent curae arcu cursus parturient consectetur penatibus euismod natoque auctor a maximus inceptos.",
    admin: "Yara Martinez",
    techStack: ["HTML", "CSS", "JavaScript"],
    otherTags: ["Portfolio", "Personal", "Web App"],
    mentors: ["Dr. Patel, Pradeepto"],
    edition: "2024",
  }
];

export default dummyProjects;
