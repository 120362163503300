import React, { useState } from "react";
import dummyProjects from "./dummyProjects";
import "./Projects.css";

const Projects = () => {
  const [selectedEdition, setSelectedEdition] = useState("2024");
  const [selectedTechStack, setSelectedTechStack] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const editions = Array.from(new Set(dummyProjects.flatMap(project => project.edition)));
  const techStacks = Array.from(new Set(dummyProjects.flatMap(project => project.techStack)));

  const filteredProjects = dummyProjects.filter(project => 
    (selectedEdition === "" || project.edition === selectedEdition) &&
    (selectedTechStack === "" || project.techStack.includes(selectedTechStack)) &&
    (searchQuery === "" || 
      project.title.toLowerCase().includes(searchQuery.toLowerCase()) || 
      project.admin.toLowerCase().includes(searchQuery.toLowerCase()) ||
      project.mentors.some(mentor => mentor.toLowerCase().includes(searchQuery.toLowerCase()))
    )
  );

  const [selectedProject, setSelectedProject] = useState(null);

  const openPopup = (project) => {
    setSelectedProject(project);
  };

  const closePopup = () => {
    setSelectedProject(null);
  };

  return (
    <div className="projects-page">
      <div className="filters">
        <div className="edition-filter">
          <label htmlFor="edition-select">Edition:</label>
          <select
            id="edition-select"
            value={selectedEdition}
            onChange={(e) => setSelectedEdition(e.target.value)}
          >
            <option value="">All</option>
            {editions.map((edition) => (
              <option key={edition} value={edition}>{edition}</option>
            ))}
          </select>
        </div>
        <div className="techstack-filter">
          <label htmlFor="techstack-select">Tech Stack:</label>
          <select
            id="techstack-select"
            value={selectedTechStack}
            onChange={(e) => setSelectedTechStack(e.target.value)}
          >
            <option value="">All</option>
            {techStacks.map((stack) => (
              <option key={stack} value={stack}>{stack}</option>
            ))}
          </select>
        </div>
        <div className="search-filter">
          <label htmlFor="search-input">Search:</label>
          <input
            id="search-input"
            type="text"
            placeholder="Search by title, admin, or mentor"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      <div className="projects-grid">
        {filteredProjects.map((project) => (
          <div key={project.id} className="project-card">
            <div>
                <div className="project-id">ID: {project.id}</div>
                <div className="project-title">{project.title}</div>
                <div className="project-admin">Admin: {project.admin}</div>
                <div className="project-tags">
                {project.techStack.map((tag) => (
                    <span key={tag} className="tag">{tag}</span>
                ))}
                {project.otherTags.map((tag) => (
                    <span key={tag} className="tag">{tag}</span>
                ))}
                </div>
            </div>
            
            <button className="description-button" onClick={() => openPopup(project)}>
              Description
            </button>
          </div>
        ))}
      </div>
      {selectedProject && (
        <div className="popup">
          <div className="popup-content">
            <button className="close-popup" onClick={closePopup}>×</button>
            <h2>{selectedProject.title}</h2>
            <p><strong>Project ID:</strong> {selectedProject.id}</p>
            <p><strong>GitHub Repo:</strong> <a href={selectedProject.github} target="_blank" rel="noopener noreferrer">{selectedProject.github}</a></p>
            <p><strong>Deployed Link:</strong> <a href={selectedProject.deployed} target="_blank" rel="noopener noreferrer">{selectedProject.deployed}</a></p>
            <p><strong>Description:</strong> {selectedProject.description}</p>
            <p><strong>Admin(s):</strong> {selectedProject.admin}</p>
            <div className="popup-tags">
              {selectedProject.techStack.map((tag) => (
                <span key={tag} className="tag">{tag}</span>
              ))}
              {selectedProject.otherTags.map((tag) => (
                <span key={tag} className="tag">{tag}</span>
              ))}
            </div>
            <p><strong>Mentor(s):</strong> {selectedProject.mentors.join(", ")}</p>
            <p><strong>Edition:</strong> {selectedProject.edition}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Projects;
